import * as React from "react";
import { isArray } from "lodash";

import * as styles from "./verticalWrapPanel.module.scss";

interface IProps {
  maxRows?: 2 | 3 | 4 | 5;
  maxCols?: 2;
  minCols?: number;
  horizontalGap?: number;
  verticalGap?: number;
  packing?: "tight" | "stretch" | number;
}

export const VerticalWrapPanel: React.FunctionComponent<
  React.PropsWithChildren<IProps>
> = ({ maxRows, minCols, maxCols, horizontalGap, verticalGap, packing, children }) => {
  let gridAutoColumns;
  if (packing && isNaN(Number(packing))) {
    gridAutoColumns = packing === "tight" ? "max-content" : "auto";
  } else if (packing) {
    gridAutoColumns = `${packing}px`;
  }
  let gridTemplateColumns = "initial";
  if (minCols) {
    gridTemplateColumns = `repeat(${minCols}, 1fr)`;
  }
  let gridAutoFlow;
  if (children && isArray(children)){
    if (children.length === 6){
      maxRows = 2;
    }
  }
  if (maxCols) {
    gridTemplateColumns = `1fr 1fr`;
    gridAutoFlow = 'column';
    maxRows = 4;
  }
  return (
    <div
      className={styles.verticalWrapPanel}
      data-mode={packing || 'stretch'}
      style={{
        gridTemplateRows: `repeat(${maxRows || 3}, 1fr)`,
        columnGap: `${horizontalGap || 0}px`,
        rowGap: `${verticalGap || 0}px`,
        gridAutoColumns,
        gridTemplateColumns,
        gridAutoFlow
      }}
    >
      {children}
    </div>
  );
};
