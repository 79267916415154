import { ClassNames } from "@rocketmakers/armstrong-edge";
import { graphql } from "gatsby";
import * as React from "react";
import { SiteMapFragment } from "../generated/graphql";

import * as styles from "./mapComponent.module.scss";

interface IProps {
  slice: SiteMapFragment;
}

const layers = [
  { id: "soundstage", label: "Soundstages" },
  { id: "workshops", label: "Workshops" },
  { id: "screening", label: "Screening" },
  { id: "backlot", label: "Backlot" },
  { id: "storage", label: "Storage" },
  { id: "offices", label: "Offices" },
  { id: "accommodation", label: "Accommodation" },
  { id: "serviceproviders", label: "Service providers" },
  { id: "carpark", label: "Car Park" }
]

export const MapComponent: React.FC<IProps> = ({
  slice,
}) => {
  const [selectedLayer, setSelectedLayer] = React.useState(layers[0]);

  return <div className={ClassNames.concat("fixed-width", styles.map)}>
    <div className={styles.mapKey}>
      <div className={styles.mapKeyTitle}>
        <h3>{slice.primary.site_map_title}</h3>
        <p>{slice.primary.site_map_description}</p>
      </div>
      <div className={styles.mapKeyList}>
      {layers.map((layer, index) =>
      <div key={`key-item-${layer.id}`} data-selected={layer.id === selectedLayer.id} onClick={() => setSelectedLayer(layer)}>
        {`0${index+1}`}&nbsp;&nbsp;&nbsp;{layer.label}
      </div>  
      )}
      </div>
      
    </div>
    <div className={styles.mapArea}>
      <img className={styles.baseLayer} alt="Blank map layer" src={`/images/map/blank.svg`}/>
      {layers.map(layer => 
        <img key={`key-image-${layer.id}`} alt={layer.label} data-visible={layer.id === selectedLayer.id} src={`/images/map/${layer.id}.svg`}/>      
      )}
    </div>
    <div className={styles.mobileMapKeyTitle}>
      <h3>{slice.primary.site_map_title}</h3>
      <p>{slice.primary.site_map_description}</p>
    </div>
  </div>;
};

export const query = graphql`
  fragment SiteMap on PrismicStudiosPageDataBodySiteMap {
    primary {
      site_map_title
      site_map_description
    }
  }
`;