import * as React from "react";

import * as styles from "./copyBlock.module.scss";

interface IProps {
  header?: string | null;
  size: "large" | "small";
  mainHeader?: boolean;
  withBorders?: boolean;
}

export const CopyBlock: React.FunctionComponent<
  React.PropsWithChildren<IProps>
> = ({ header, withBorders, children, size, mainHeader }) => {
  return (
    <div
      className={styles.copyBlock}
      data-size={size}
      data-borders={withBorders}
    >
      {mainHeader ? <h1>{header}</h1> : <h3>{header}</h3>}
      {children}
    </div>
  );
};
