import * as React from "react";

import * as styles from "./splitBlock.module.scss";

interface IProps {
  split?: 2 | 3 | 4;
}

export const SplitBlock: React.FunctionComponent<
  React.PropsWithChildren<IProps>
> = ({ split, children }) => {
  return (
    <div className={styles.splitBlock} data-split={split || 2}>
      {children}
    </div>
  );
};
