import { graphql, PageProps, Link } from "gatsby";
import * as React from "react";
import { Card } from "../components/card";
import { CopyBlock } from "../components/copyBlock";
import { HeroSwiper } from "../components/heroSwiper";
import { SplitBlock } from "../components/splitBlock";
import { StudioPageQuery } from "../generated/graphql";
import { GatsbyImage } from "gatsby-plugin-image";
import Shell from "../layout/shell";

import { PrismicRichText } from "@prismicio/react";
import { VerticalWrapPanel } from "../components/verticalWrapPanel";
import { MapComponent } from "../components/mapComponent";

interface IStudioPageProps {
  data: StudioPageQuery;
}

const StudioPage: React.FC<IStudioPageProps> = ({ data: pageData }) => {
  const data = pageData.prismicStudiosPage?.data;
  if (!data) {
    return null;
  }
  const lists: any = data.body.filter((b) => b.slice_type === "block_list");
  const compactLists: any[] = lists.filter(
    (b) => b.primary.display_mode === "Compact"
  );
  const wideLists: any[] = lists.filter(
    (b) => b.primary.display_mode === "Wide"
  );

  React.useEffect(() => {
    const element = document.querySelector("a[aria-current='page']");
    if (element) {
      element.scrollIntoView(false);
    }
  }, []);

  return (
    <Shell rootActive={true}>
      <HeroSwiper>
        {data.hero_gallery?.map((img) => (
          <GatsbyImage
            key={img?.image?.alt}
            image={img?.image?.gatsbyImageData}
            alt={img?.image?.alt || "Hero image"}
          />
        ))}
      </HeroSwiper>

      <div className="fixed-width">
        <SplitBlock>
          <CopyBlock
            header={data.main_section_title}
            size="large"
            mainHeader={true}
            withBorders={true}
          >
            <PrismicRichText field={data.main_section_content?.richText} />
          </CopyBlock>
          <Card>
            <CopyBlock header={data.right_section_title} size="large">
              <PrismicRichText field={data.right_section_content?.richText} />
              <button className="alt">
                <Link
                  to="/get-in-touch"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  For more information
                </Link>
              </button>
            </CopyBlock>
          </Card>
        </SplitBlock>
      </div>
      {data.body.find((b) => b.slice_type === "site_map") && (
        <MapComponent
          slice={data.body.find((b) => b.slice_type === "site_map") as any}
        />
      )}
      <div className="fixed-width">
        <h3>{data.service_lists_title}</h3>
        <div className="list-block" data-lists={compactLists.length}>
          {compactLists.map((clist) => (
            <div key={clist.id}>
              <h3>{clist.primary.title}</h3>
              <VerticalWrapPanel
                maxCols={compactLists.length === 2 ? 2 : undefined}
                maxRows={4}
                verticalGap={20}
                packing="tight"
                horizontalGap={100}
              >
                {clist.items.map((li) => (
                  <div key={li.list_item_title} className="compact-list-item">
                    {li.list_item_image && (
                      <GatsbyImage
                        alt={li.list_item_image.alt || "List item icon"}
                        image={li.list_item_image.gatsbyImageData}
                      />
                    )}
                    <div className="compact-list-item-copy">
                      <strong>{li.list_item_title}</strong>
                      <div>{li.list_item_description}</div>
                    </div>
                  </div>
                ))}
              </VerticalWrapPanel>
            </div>
          ))}
        </div>

        <hr />

        {wideLists.map((wlist) => (
          <div key={wlist.id}>
            <h3 style={{ marginBottom: "40px" }}>{wlist.primary.title}</h3>
            <VerticalWrapPanel
              maxRows={3}
              horizontalGap={20}
              verticalGap={40}
              minCols={3}
            >
              {wlist.items.map((li) => (
                <div key={li.list_item_title} className="loose-list-item">
                  <strong>{li.list_item_title}</strong>
                  <p>{li.list_item_description}</p>
                </div>
              ))}
            </VerticalWrapPanel>
          </div>
        ))}
      </div>
    </Shell>
  );
};

export const query = graphql`
  query StudioPage($uid: String!) {
    prismicStudiosPage(uid: { eq: $uid }) {
      data {
        hero_gallery {
          image {
            gatsbyImageData(width: 1186, imgixParams: { auto: "" })
            alt
          }
        }
        main_section_title
        main_section_content {
          richText
        }
        right_section_title
        right_section_content {
          richText
        }
        service_lists_title
        body {
          ... on PrismicStudiosPageDataBodySiteMap {
            slice_type
            primary {
              site_map_title
              site_map_description
            }
          }
          ... on PrismicStudiosPageDataBodyBlockList {
            id
            slice_type
            primary {
              display_mode
              title
            }
            items {
              list_item_image {
                alt
                gatsbyImageData
                url
              }
              list_item_description
              list_item_title
            }
          }
        }
      }
      uid
    }
  }
`;

export default StudioPage;
